.ResumePageContainer{
    padding-top: calc(70px + 20vh);
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: center;
    background-color: white;
}

.resume{
    font-size: 70px;
    color: #363636;
    padding-bottom: 2vh;
    text-align: center;
}

.blueLink{
    text-decoration: underline;
    color: #1687A7;
}

.resumeLink{
    font-size: 24px;
    color:#5F5F5F;
    text-decoration: none;
    padding-bottom: 7px;
}

@media screen and (max-width: 650px) {
    .resumeLink{
        font-size: 4.5vw;
    }
    .resume{
        font-size: 11vw;   
    }
}
