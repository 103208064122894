.dotBlue{
    color: #1687A7;
}

.collinsLink{
    color: #1687A7;
    text-decoration: underline;
}

.pageContainer{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 70px;
    background-color: white;
    display: flex;
    align-items: center;    
    justify-content: center; 
    height: 100vh;
}

.outerContentDiv{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}

.textBlock{
    flex: 66%;
}

.hello{
    font-size: 48px;
    color: #363636;
    padding-bottom: min(1vh,26px);   
}

.samuel, .welcome{
    font-size: min(12vh,95px);   
}

.samuel{
    color: #1687A7;
}

.welcome{
    color: #363636;
    padding-bottom: min(3vh,45px);   
}

.summary{
    font-size: 24px;
    color:#5F5F5F;
    max-width: 750px;
}

.computerDiv{
    max-width: 537px;
    flex: 34%;
    display: flex;
    align-items: center;
}

.computerGif{
    max-width: min(80vh,537px);
}

/*adjustments for when word portfolio wraps around*/
@media screen and (max-width: 1652px) {
    .textBlock{
        max-width: 710px;
    }
}

/*adjustments for when word Micallef wraps around*/
@media screen and (max-width: 1288px){
    .textBlock{
        max-width: none;
    }
    .outerContentDiv{
        flex-direction: column;
    }
    .computerGif,.computerDiv{
       display: none;
    }
}

@media screen and (max-width: 1114px){
    .textBlock{
        max-width: 710px;
    }
}

@media screen and (max-width: 770px) {
    .computerDiv{
        display: flex;
        margin-top: 5px;
        max-width: 100vw;
        justify-content: center;
    }
    .computerGif {
        display:initial;
        height: min(30vh, 50vw);        
    }
    .hello, .welcome{
        padding-bottom: 10px;
    }
    .hello{
        font-size: 6vw;
    }
    .samuel, .welcome{
        font-size: min(7vh,11.5vw);   
    }
    .welcome{
        padding-bottom: 10px;
    }
    .summary{
        font-size: 4vw;
    }
}