.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    transition: 0.1s;
    position: fixed;
    width: 100%;
    height: 70px;
    z-index: 10;
    background-color: white;
}
.header.active{
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
    transition-delay: 0.1s;
}

.nav-menu{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-right: 20px;
}

.Mlogo{
    font-size: 20;
    padding: 0.475rem;
    z-index: 1;
}

.nav-menu li a{
    font-size: 20;
    padding: 0 1.35rem;
    padding: 1rem;
}
.nav-menu li a:hover{
    color: #1687A7;
}

.hamburger{
    display: none;
    padding: 1rem;
    z-index: 1;
}

.menuFooter{
    height: 0px;
    width: 0px;
    font-size: 0;
}

.samGif{
    width: 0;
    height: 0;
}

@media screen and (max-width: 540px) {
    .nav-menu{
        background-color: white;
        flex-direction: column;
        align-items: left;
        width: 100vw;
        height: 100vh;
        min-height: 590px;
        position: fixed;
        top:-200%;
        z-index: 0;
        transition: 0.3s;
    }
    .nav-menu.active{
        top:0;
    }
    .hamburger{
        display:initial;
    }
    .nav-menu li{
        padding-top: 0.8rem;
    }
    .nav-menu li a{
        font-size: 3.2rem; 
        padding-left: 0; 
        margin-left: 15%;
    }
    .menuTitle{
        display: initial;
        padding-left: 15%; 
    }
    .line{
        height: 5px;
    }
    .menuFooter{
        height: 110px;
        width: 100vw;
        font-size: 20px;
        position: absolute;
        bottom: 75px;
        z-index: -4;
    } 
    .samGif{
        position: absolute;
        bottom: 0px;
        right: 0; 
        width: 160px;
        height: 100px;
    }
   .alot{
        position: absolute;
        right: 140px; 
        padding-top: 30px;
        font-size: 0.8rem;
        max-width: 80px;
    }

    .header{
        height: 55px;
    }
}

@media screen and (max-height: 700px)and (max-width: 540px) {
    .nav-menu{
        justify-content:right;
    }

    .homeLink{
        margin-top: 12vh;
    }
}