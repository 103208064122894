
.AboutTitle{
    font-size: 70px;
    color: #363636;
    padding-bottom: 2vh;
    text-align: center;
}

.AboutText{
    font-size: 24px;
    color:#5F5F5F;
    margin-bottom: 1rem;
}

.inLineProjectLink, .inLineContactLink{
    color: #1687A7;
    text-decoration:underline;
}

.AboutPageContainer{
    padding-top: calc(70px + 10vh);
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    background-color: white;
}

.AboutContentContainer{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-bottom: 50px;
    max-width: 800px;
    text-align: left;
}

.HikingPortrait{
    max-width: 90vw;
    width: 800px;
    opacity: 0.2;
}

@media screen and (max-width: 1000px) {
    .HikingPortrait{
        margin-left: 0px;
        margin-top: 3vh;
    }
    .AboutContentContainer{
        flex-direction: column;
    }
    .PortaitDiv{
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 650px) {
    .AboutTxt{
        font-size: 4vw;
        text-align: left;
    }
    .About{
        font-size: 11vw;   
    }
}

@media screen and (max-width: 614px) {
    .AboutTxt{
        text-align: justify;
    }
}

@media screen and (max-width: 332px) {
    .AboutTxt{
        text-align: left;
    }
}
@media screen and (max-width: 650px) and (max-height: 1300px) {
    .AboutPageContainer{
        padding-top: calc(70px + 5vh);
    }
}