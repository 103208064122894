@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');

*{
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1,h4,p,a{
  text-decoration: none;
  color: black;
}

ul{
  list-style-type: none;
}