.madeWith, .Copyright{
    font-size: 14px;
    color: #949494;
    position: absolute;
    top: 97.5%;
}

.madeWith{
    left: 20px;
}
.Copyright{
    right: 20px;
}
.view{
    text-decoration: underline;
    color: #949494;
}
@media screen and (max-width: 1410px){
    .Copyright{
        font-size: 0;
    }
    .madeWith{
        font-size: 0;
    }
}

@media screen and (max-height: 810px){
    .Copyright{
        font-size: 0;
    }
    .madeWith{
        font-size: 0;
    }
}