.ContactBlueUnderline{
    color: #1687A7;
    text-decoration: underline;
}
.ContactBlue{
    color: #1687A7;
}
.Linked{
    color:#5F5F5F;
}
.ContactEmail, .ContactLinkedin, .ContactTwitter{
    margin-bottom: 7px;
}

.lets{
    font-size: 70px;
    color: #363636;
    padding-bottom: 2vh;
}

.ContactPageContainer{
    padding-top: calc(70px + 20vh);
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: center;
    background-color: white;
}

.ContactLinks{
    font-size: 24px;
    color:#5F5F5F;
    text-align: center;
}

@media screen and (max-width: 650px) {
    .ContactLinks{
        font-size: 4.5vw;
    }
    .lets{
        font-size: 11vw;   
    }
}

